jQuery.noConflict();
(function( $ ) {
    $(function() {
        //Document Ready STARTS

        if ($('.confirm-wrap').length>0) {
            if ( Cookies.get('professional') == undefined) {
                console.log('not professional');
                $('.confirm-wrap').slideToggle();
                $('body').addClass('scroll-lock');
            }
            else {
                console.log('professional');
            }
        }

        $('#professional-conf').on('click', function(){
            Cookies.set('professional', 'y', { expires: 1 });
            $('.confirm-wrap').slideToggle();
            $('body').removeClass('scroll-lock');
            setTimeout(function(){
                $('.confirm-wrap').remove();
            }, 1500);
        });

        //Document Ready ENDS
    });
})(jQuery);