/*-----------------------------------------*/
/*-----------------------------------------*/
/*---------- BASE MAIN JS FILE -----------*/
/*-----------------------------------------*/
/*-----------------------------------------*/

jQuery(document).foundation();
jQuery.noConflict();
(function( $ ) {
    $(function() {
        //Document Ready STARTS

        // Homepage Slider //
        $('.slider').slick({
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: true,
            dots: false,
            pauseOnDotsHover: true,
            speed: 900
        });

        setTimeout(function(){
            var homeSlickHeight = $('.slick-list').height();
            if (homeSlickHeight != 0) {
                $('.slider.slick-slider').height(homeSlickHeight);
            }
        }, 500);

        $('.mslider').slick({
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: true,
            dots: false,
            pauseOnDotsHover: true,
            speed: 900
        });

        // Product Tag Dropdown //
        $(".tagHeader").click(function () {
            $(".tagContainer").fadeToggle();
        });
        $(document).mouseup(function (e)
        {
            var container = $(".tagContainer");
            if (!container.is(e.target)
                && container.has(e.target).length === 0)
            {
                container.fadeOut();
            }
        });

        $('.fw-slider').flexslider({
            animation: "slide",
            directionNav: false,
            smoothHeight: true,
            controlNav: false,
            animationLoop: true
        });

        setTimeout(function(){
            reviewsWidgetFix();
        }, 5000);

        function reviewsWidgetFix() {
            if ($('#richSnippetReviewsWidget-defaultContainer').length>0) {
                var bk = $('#richSnippetReviewsWidget-defaultContainer').html();
                $('#richSnippetReviewsWidget-defaultContainer').remove();
                $('#richSnippetReviewsWidget-defaultContainer').remove();
                $('#richSnippetReviewsWidget-defaultContainer').remove();
                $('body').append('<div id="richSnippetReviewsWidget-defaultContainer">'+bk+'</div>');
            }
            setTimeout(function(){
                reviewsWidgetFix();
            }, 5000);
        }

        $(window).on('load', function(){
            afterLoad();
        });

        function afterLoad() {
            $('.mobile-nav').attr('style', '');
        }

        // mobile mnu toggle functions
        $('.hamburger').on('click', function(e){
            e.preventDefault();
            $(this).toggleClass('is-active');
            $('.mobile-nav').toggleClass('active');
            $('body').toggleClass('scroll-lock');
        });
        $('.menu-close .close-menu').on('click', function(e){
            e.preventDefault();
            console.log('CLICK')
            $('.hamburger').toggleClass('is-active');
            $('.mobile-nav').toggleClass('active');
            $('body').toggleClass('scroll-lock');
        });
        //Document Ready ENDS
    });
})(jQuery);

